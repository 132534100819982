import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Header from '../components/Header';
import Logo from '../assets/YAIR_LOGO.svg';

const ns = 'index-page';

const NotFoundPage = ({ location }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  });

  return (
    <Layout location={location} title="YAIR">
      <Seo title="404" />
      <div className={rootClassnames}>
        <Header />
        <div className={`${ns}__content`}>
          <div className={`${ns}__not-found-image`}>
            <StaticImage src="../images/NotFound.png" alt="What's Up Foo?" placeholder="blurred" />
          </div>
          <p className={`${ns}__not-found-text`}>Where you going foo? That Page Doesn’t Exist Or Is Unavailable</p>
        </div>
        <div className={`${ns}__logo`}>
          <div className={`${ns}__logo-container`}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};
